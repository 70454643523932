@font-face {
  font-family: "Poppins";
  src: url("../fonts/Poppins_Light.woff2") format("woff2"),
    url("../fonts/Poppins_Light.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "PoppinsBold";
  src: url("../fonts/Poppins_Bold.woff2") format("woff2"),
    url("../fonts/Poppins_Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
